<template>
    <b-modal
        :id="id"
        centered
        title="Adicionar campo de formulário"
        @hide="hide"
    >
        <v-select
            v-model="selectedForm"
            :get-option-label="(option) => option.name"
            :reduce="(option) => option._id"
            :options="formItems"
            placeholder="Selecione um formulário"
            class="flex-grow-1 mb-2"
        >
            <span slot="no-options">Desculpe, não há opções correspondentes.</span>
        </v-select>

        <v-select
            v-model="selectedField"
            :options="fields"
            placeholder="Opções de campo"
            class="flex-grow-1"
            :loading="loading"
        >
            <span slot="no-options">Desculpe, não há opções correspondentes.</span>
        </v-select>

        <template v-slot:modal-footer="{ ok, cancel }">
            <button @click="cancel()" class="btn btn-secondary">Cancelar</button>
            <button @click="confirm(ok)" class="btn btn-primary">Salvar</button>
        </template>
    </b-modal>
</template>

<script>
import { BModal } from "bootstrap-vue";
import api from "@/api";
import VSelect from "vue-select";

export default {
    name: "AddFieldName",

    props: {
        id: {
            type: String,
            default: 'addFieldNameModal'
        }
    },

    data() {
        return {
            selectedForm: null,
            selectedField: null,
            formItems: [],
            fields: [],
            loading: false
        }
    },

    components: {
        BModal,
        VSelect
    },

    mounted() {
        this.getForms();
    },

    watch: {
        selectedForm: {
            handler(val) {
                this.selectedField = null;
                this.fields = [];

                if (val)
                    return this.getFields();
            }
        }
    },

    emits: ['ignore', 'select'],

    methods: {
        hide(bvModalEvent) {
            this.selectedForm = null;
            this.selectedField = null;
            this.fields = [];

            if(bvModalEvent.trigger != 'ok')
                this.$emit('ignore');
        },
        getFields() {
            const params = {
                form_id: this.selectedForm,
                block_main_fields: true
            };

            this.loading = true;
            api.get('/forms/fields', {params})
                .then(response => {
                    const { body } = response.data;
                    this.fields = body;
                })
                .finally(() => this.loading = false);
        },

        getForms() {
            this.$store
                .dispatch('form/index', {
                    auth: this.$store.getters["user/getAuth"],
                    with_customers: false
                })
                .then(() => {
                    this.formItems = this.$store.getters["form/get"];
                });
        },

        confirm(ok) {
            if (this.selectedForm && this.selectedField) {
                this.save();
                this.$swal('Sucesso', 'Campo adicionado com sucesso', 'success');
                return ok();
            }

            this.$swal('Atenção', 'Por favor, preencha os campos corretamente', 'info');
        },

        save() {
            const formId = this.formItems.filter(form => form._id == this.selectedForm);
            this.$emit('select', { field: this.selectedField, formId: formId[0]._id });
        }
    }
}
</script>
