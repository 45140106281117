<template>
	<section>
		<validation-observer ref="segmentationValidation">
			<b-card class="position-relative p-5" no-body>
				<loading v-if="loading" />
				<section>
					<h3 class="text-left text-secondary font-weight-bolder mb-2">Segmentação</h3>

					<div class="row mt-3">
						<div
							class="form-group d-flex align-items-center col-12 col-md-6 col-lg-8 text-left"
						>
							<v-select
								v-model="selected"
								:disabled="!editable || create"
								:options="segmentationItems"
								:placeholder="
									loadingSegmentations
										? 'Buscando segmentações...'
										: 'Selecionar uma segmentação'
								"
								@input="(e) => (segmentationId = e.code)"
								class="flex-grow-1"
								:loading="loadingSegmentations"
							>
								<span slot="no-options">
									Desculpe, não há opções correspondentes.
								</span>
							</v-select>
						</div>
						<div class="form-group col-12 col-md-6 col-lg-4 text-left d-flex">
							<template v-if="!create">
								<button
									:disabled="!editable || create"
									class="btn btn-outline-primary flex-grow-1 mr-2"
									@click="showCreateForm"
									v-if="!blockChange"
								>
									Criar novo
								</button>
								<button class="btn btn-outline-primary flex-grow-1" @click="cancel">
									Cancelar
								</button>
							</template>
							<button
								v-else
								class="btn btn-outline-primary flex-grow-1"
								@click="create = !create"
							>
								Cancelar
							</button>
						</div>
					</div>
				</section>

				<section v-if="create" class="text-left mb-4">
					<h4 class="text-blue font-weight-bolder mt-5">Nova Segmentação</h4>

					<validation-observer ref="segmentationFormValidation">
						<validation-provider #default="{ errors }" rules="required">
							<input
								v-model="name"
								:class="`my-3 form-control ${errors[0] && 'is-invalid'}`"
								:disabled="!editable"
								placeholder="Nome"
								type="text"
							/>
						</validation-provider>
						<section :class="editable ? '' : 'disabled'">
							<div
								class="d-flex justify-content-between align-items-center flex-wrap"
							>
								<h5 class="text-dark text-left font-weight-bold">Dados Gerais</h5>
								<h5 class="d-flex align-items-center m-0">
									Total de disparos:
									<span
										class="spinner-border spinner-border-sm text-primary m-0 ml-2"
										v-if="searching"
										role="status"
									></span>
									<span class="text-blue h3 font-weight-bolder m-0 ml-2" v-else>
										{{ shots_count || "-" }}
									</span>
								</h5>
							</div>
							<div class="row mt-3">
								<div
									v-for="(field, i) in segmentation.general_data"
									:key="`general-data-${i}`"
									class="col-12 col-sm-6 col-md-4 col-lg-3 my-2 d-flex align-items-center"
								>
									<validation-provider #default="{ errors }" rules="required">
										<div class="input-floating flex-grow-1">
											<input
												type="text"
												v-if="'form' in field && field.form"
												v-model="field.value"
												:class="`form-control form-input ${
													errors[0] && 'is-invalid'
												}`"
												:disabled="'form' in field"
											/>
											<multiselect
												v-else
												style="width: 18rem"
												:multiple="true"
												:options="field.answers"
												placeholder="Selecione os dados"
												:id="`general-data-field-${i}`"
												v-model="field.value"
												:class="`form-control ${errors[0] && 'is-invalid'}`"
											>
												<template
													slot="selection"
													slot-scope="{ values, search, isOpen }"
												>
													<span
														class="multiselect__single"
														v-if="values.length &amp;&amp; !isOpen"
													>
														{{ values.length }} opções selecionadas
													</span>
												</template>
											</multiselect>
											<label :for="`general-data-field-${i}`">
												{{ field.name }}
											</label>
										</div>
									</validation-provider>
									<button
										class="remove-btn ml-2"
										@click="() => segmentation.general_data.splice(i, 1)"
									>
										<font-awesome-icon icon="fa-solid fa-minus" />
									</button>
								</div>
								<div class="col-12 mt-4 d-flex align-items-center">
									<span
										v-b-modal.addGeneralDataModal
										class="d-flex align-items-center"
										role="button"
									>
										<span class="circle-icon mr-2">+</span>
										<span class="m-0 h6 font-weight-bold">
											Adicionar um novo campo
										</span>
									</span>
								</div>
							</div>
						</section>

						<section :class="editable ? '' : 'disabled'" class="mt-5">
							<div class="d-flex align-items-center">
								<h5 class="text-dark text-left font-weight-bold">
									Respostas de Clientes
								</h5>
							</div>
							<div class="row mt-3">
								<div
									v-for="(field, i) in segmentation.customer_answers"
									:key="`answer-data-${i}`"
									class="col-12 col-sm-6 col-md-4 col-lg-3 my-2 d-flex align-items-center"
								>
									<validation-provider #default="{ errors }" rules="required">
										<div class="input-floating flex-grow-1">
											<multiselect
												style="width: 18rem"
												:multiple="true"
												:options="field.answers"
												placeholder="Selecione as opções"
												:id="`answer-data-field-${i}`"
												v-model="field.value"
												:class="`form-control ${errors[0] && 'is-invalid'}`"
												class="form-control"
											>
												<template
													slot="selection"
													slot-scope="{ values, search, isOpen }"
												>
													<span
														class="multiselect__single"
														v-if="values.length &amp;&amp; !isOpen"
													>
														{{ values.length }} opções selecionadas
													</span>
												</template>
											</multiselect>
											<label :for="`answer-data-field-${i}`">
												{{ field.name }}
											</label>
										</div>
									</validation-provider>
									<button
										class="remove-btn ml-2"
										@click="() => segmentation.customer_answers.splice(i, 1)"
									>
										<font-awesome-icon icon="fa-solid fa-minus" />
									</button>
								</div>
								<div class="col-12 mt-4 d-flex align-items-center">
									<span
										v-b-modal.addCustomerAnswerModal
										class="d-flex align-items-center"
										role="button"
									>
										<span class="circle-icon mr-2">+</span>
										<span class="m-0 h6 font-weight-bold">
											Adicionar um novo campo
										</span>
									</span>
								</div>
							</div>
						</section>

						<section :class="editable ? '' : 'disabled'" class="mt-5">
							<div class="d-flex align-items-center mb-3">
								<h5 class="text-dark text-left font-weight-bold">
									Dados Comportamentais na Ferramenta
								</h5>
							</div>
							<div
								v-for="(field, i) in segmentation.behavioral_data"
								:key="`behavior-data-${i}`"
								class="row"
							>
								<div
									class="my-2 col-12 col-sm-6 col-lg-2 d-flex align-items-center"
								>
									<validation-provider
										#default="{ errors }"
										class="flex-grow-1"
										rules="required"
									>
										<div class="input-floating flex-grow-1">
											<select
												:id="`behavior-action-field-${i}`"
												v-model="field.action"
												:class="`form-control ${errors[0] && 'is-invalid'}`"
											>
												<option>Recebeu</option>
												<option>Abriu</option>
												<option>Interagiu</option>
											</select>
											<label :for="`behavior-action-field-${i}`">Ação</label>
										</div>
									</validation-provider>
								</div>
								<div
									class="my-2 col-12 col-sm-6 col-lg-2 d-flex align-items-center"
								>
									<validation-provider
										#default="{ errors }"
										class="flex-grow-1"
										rules="required"
									>
										<div class="input-floating flex-grow-1">
											<select
												:id="`behavior-condition-field-${i}`"
												v-model="field.condition"
												:class="`form-control ${errors[0] && 'is-invalid'}`"
											>
												<option>Menos de</option>
												<option>Mais de</option>
												<option>Igual a</option>
												<option>Diferente de</option>
											</select>
											<label :for="`behavior-condition-field-${i}`">
												Condição
											</label>
										</div>
									</validation-provider>
								</div>
								<div
									class="my-2 col-12 col-sm-6 col-lg-2 d-flex align-items-center"
								>
									<validation-provider
										#default="{ errors }"
										class="flex-grow-1"
										rules="required|min:0"
									>
										<div class="input-floating flex-grow-1">
											<input
												:id="`behavior-quantity-field-${i}`"
												v-model="field.quantity"
												:class="`form-control ${errors[0] && 'is-invalid'}`"
												min="0"
												type="number"
											/>
											<label :for="`behavior-quantity-field-${i}`">
												Qtd.
											</label>
										</div>
									</validation-provider>
								</div>
								<div
									class="my-2 col-12 col-sm-6 col-lg-3 d-flex align-items-center"
								>
									<validation-provider
										#default="{ errors }"
										class="flex-grow-1"
										rules="required"
									>
										<div class="input-floating flex-grow-1">
											<select
												:id="`behavior-type-field-${i}`"
												v-model="field.type"
												:class="`form-control ${errors[0] && 'is-invalid'}`"
											>
												<option>Divulgação</option>
												<option>Interação</option>
												<option>Qualquer</option>
											</select>
											<label :for="`behavior-type-field-${i}`">
												Tipo de Campanha
											</label>
										</div>
									</validation-provider>
								</div>
								<div
									class="my-2 col-12 col-sm-6 col-lg-3 d-flex align-items-center"
								>
									<validation-provider
										#default="{ errors }"
										class="flex-grow-1"
										rules="required"
									>
										<div class="input-floating flex-grow-1">
											<b-form-datepicker
												:id="`behavior-type-field-${i}`"
												v-model="field.period.initial_date"
												:class="`form-control date-input ${
													errors[0] && 'is-invalid'
												}`"
												:date-format-options="{
													year: 'numeric',
													month: 'numeric',
													day: 'numeric'
												}"
												placeholder="Data inicial"
												:max="max_date(field.period.final_date)"
											></b-form-datepicker>
											<label :for="`behavior-type-field-${i}`">
												Data inicial
											</label>
										</div>
									</validation-provider>
								</div>
								<div
									class="my-2 col-12 col-sm-6 col-lg-3 d-flex align-items-center"
								>
									<validation-provider
										#default="{ errors }"
										class="flex-grow-1"
										rules="required"
									>
										<div class="input-floating flex-grow-1">
											<b-form-datepicker
												:id="`behavior-type-field-${i}`"
												v-model="field.period.final_date"
												:class="`form-control date-input ${
													errors[0] && 'is-invalid'
												}`"
												:date-format-options="{
													year: 'numeric',
													month: 'numeric',
													day: 'numeric'
												}"
												placeholder="Data final"
												:max="today()"
												:min="min_date(field.period.initial_date)"
											></b-form-datepicker>
											<label :for="`behavior-type-field-${i}`">
												Data final
											</label>
										</div>
									</validation-provider>

									<button
										class="remove-btn ml-2"
										@click="() => segmentation.behavioral_data.splice(i, 1)"
									>
										<font-awesome-icon icon="fa-solid fa-minus" />
									</button>
								</div>
							</div>
							<div class="row">
								<div class="col-12 mt-4 d-flex align-items-center">
									<span
										class="d-flex align-items-center"
										role="button"
										@click="addBehavior"
									>
										<span class="circle-icon mr-2">+</span>
										<span class="m-0 h6 font-weight-bold">
											Adicionar um novo comportamento
										</span>
									</span>
								</div>
							</div>
						</section>
					</validation-observer>
				</section>

				<div v-if="editable" class="row align-items-center justify-content-end mt-5">
					<div
						v-if="create"
						class="col-12 col-md-8 col-lg-6 col-xl-4 my-1 d-flex flex-wrap"
					>
						<button
							class="btn flex-grow-1 btn-outline-primary m-1 text-nowrap"
							@click.prevent="storeSegmentation"
							:disabled="searching"
						>
							{{ segmentationId !== null ? "Atualizar" : "Apenas Salvar" }}
						</button>
						<button
							class="btn flex-grow-1 btn-primary m-1 text-nowrap"
							@click="handleSave"
							:disabled="searching"
						>
							Configurar Disparo
						</button>
					</div>
					<div v-else class="col-12 col-md-8 col-lg-6 col-xl-4 my-1 d-flex flex-wrap">
						<button
							class="btn flex-grow-1 btn-primary m-1 text-nowrap"
							@click="updateCampaign('onContinue')"
							:disabled="searching"
						>
							Configurar Disparo
						</button>
					</div>
				</div>

				<div v-else class="row align-items-center justify-content-end mt-5">
					<div class="col-12 col-md-6 col-lg-4 my-1 d-flex flex-wrap">
						<button
							class="btn flex-grow-1 btn-outline-primary m-1 text-nowrap"
							:disabled="updating"
							@if="this.$props.selectedSegmentation"
							@click="updateSegmentationShots"
						>
							{{ updating ? "Atualizando..." : "Atualizar Segmentação" }}
						</button>
						<button
							class="btn flex-grow-1 btn-outline-primary m-1 text-nowrap"
							@click="editable = true"
							v-if="!blockChange"
						>
							Editar
						</button>
					</div>
				</div>
				<add-customer-answer-modal @input="(e) => segmentation.customer_answers.push(e)" />
				<add-general-data-modal @input="(e) => segmentation.general_data.push(e)" />
			</b-card>
		</validation-observer>
	</section>
</template>

<script>
import { BCard } from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { required } from "@validations";
import AddCustomerAnswerModal from "@/views/pages/dashboard/new-segmentation/components/AddCustomerAnswerModal.vue";
import AddGeneralDataModal from "@/views/pages/dashboard/new-segmentation/components/AddGeneralDataModal.vue";
import vSelect from "vue-select";
import api from "@/api";
import _ from "lodash";
import Loading from "@/views/components/Loading.vue";
import Multiselect from "vue-multiselect";
import { BFormDatepicker } from "bootstrap-vue";

const segmentation = {
	general_data: [],
	behavioral_data: [],
	customer_answers: []
};

export default {
	components: {
		BCard,
		vSelect,
		ValidationProvider,
		AddCustomerAnswerModal,
		AddGeneralDataModal,
		ValidationObserver,
		Loading,
		Multiselect,
		BFormDatepicker
	},
	props: {
		selectedSegmentation: {
			default: null
		},
		loading: {
			type: Boolean,
			default: false
		},
		blockChange: {
			type: Boolean,
			default: false
		}
	},

	data() {
		return {
			editable: true,
			name: "",
			segmentation,
			required,
			create: false,
			selected: null,
			segmentationId: null,
			shots_count: 0,
			segmentationItems: [],
			updating: false,
			searching: false,
			loadingSegmentations: false
		};
	},

	created() {
		this.loadSegmentations();
		this.editable = !this.$props.selectedSegmentation;
	},

	methods: {
		addBehavior() {
			this.segmentation.behavioral_data.push({
				action: null,
				condition: null,
				quantity: null,
				type: null,
				period: {
					initial_date: null,
					final_date: null
				}
			});
		},

		loadSegmentations() {
			let params = { all: 1 };

			this.loadingSegmentations = true;
			api.get("/segmentation/", { params })
				.then((response) => {
					this.segmentationItems = response.data.body.reduce((acc, item, idx) => {
						acc[idx] = { label: item.name, code: item._id };
						return acc;
					}, []);

					if (this.selectedSegmentation !== null) {
						this.selected = this.segmentationItems.filter(
							(item) => item.code === this.selectedSegmentation
						);
					}
				})
				.finally(() => (this.loadingSegmentations = false));
		},

		cancel() {
			this.$swal({
				title: "Deseja voltar para a lista de campanhas?",
				showCancelButton: true,
				cancelButtonText: "Não",
				confirmButtonText: "Sim",
				confirmButtonColor: "#317f98"
			}).then((result) => {
				if (result.isConfirmed) {
					this.$router.replace({ name: "campaigns/contents" });
				}
			});
		},

		getSegmentationData() {
			const segmentation = JSON.parse(JSON.stringify(this.segmentation));
			const params = {
				general_data: segmentation.general_data,
				customers_answers: segmentation.customer_answers,
				behavioral_data: segmentation.behavioral_data
			};
			this.searching = true;
			api.post("/segmentation/search", { ...params })
				.then((response) => {
					this.shots_count = response.data.body.shots_count;
				})
				.catch((response) => {
					return this.$swal({
						title: "Ocorreu um erro",
						text: response.data.message,
						icon: "error",
						confirmButtonText: "OK",
						confirmButtonColor: "#317f98"
					});
				})
				.finally(() => (this.searching = false));
		},

		filterSegmentation: _.debounce(function () {
			this.getSegmentationData();
		}, 500),

		showCreateForm() {
			this.create = true;
		},

		storeSegmentation(event = "onSave") {
			this.editable = true;
			this.$refs.segmentationFormValidation.validate().then((result) => {
				if (!result) return;

				if (this.shots_count > 15000) {
					return this.$bvToast.toast(
						"A segmentação não pode conter mais do que 15000 clientes",
						{
							title: "Segmentação",
							autoHideDelay: 2000,
							variant: "danger"
						}
					);
				}

				const method = this.segmentationId ? "put" : "post";
				const path =
					this.segmentationId !== null ? `update/${this.segmentationId}` : "store";
				api[method](`segmentation/${path}`, {
					name: this.name,
					general_data: this.segmentation.general_data,
					customers_answers: this.segmentation.customer_answers,
					behavioral_data: this.segmentation.behavioral_data
				})
					.then((response) => {
						const { body, message, type } = response.data;

						if (type === "error")
							return this.$swal("Segmentação", response.data.errors[0], type);

						this.$swal({
							title: "Segmentação",
							text: message,
							icon: type,
							confirmButtonText: "OK",
							confirmButtonColor: "#317f98"
						});

						const option = { label: body.name, code: body._id };

						if (method === "post") {
							this.segmentationItems.push(option);
						}

						this.selected = option;
						this.segmentationId = body._id;
						this.create = false;
						this.updateCampaign(event);
					})
					.finally(() => {
						this.editable = false;
					});
			});
		},

		handleSave() {
			this.$refs.segmentationValidation.validate().then((success) => {
				if (success) this.storeSegmentation("onContinue");
			});
		},
		updateSegmentationShots() {
			if (this.$props.selectedSegmentation !== null) {
				const id = this.$props.selectedSegmentation;
				this.updating = true;
				api.put(`/segmentation/update_shots/${id}`)
					.then((response) => {
						const { message, type } = response.data;
						this.$bvToast.toast(message, {
							title: "Segmentação",
							autoHideDelay: 2000,
							variant: type
						});
					})
					.catch((error) => {
						let message =
							"Não foi possível processar sua requisição corretamente. Tente novamente mais tarde.";
						if (error.response) message = error.response.data.message;

						this.$bvToast.toast(message, {
							title: "Segmentação",
							autoHideDelay: 2000,
							variant: "danger"
						});
					})
					.finally(() => {
						this.updating = false;
					});
			}
		},
		updateCampaign(event = "onContinue") {
			if (!this.selectedSegmentation && !this.segmentationId)
				return this.$swal("Selecione ou crie uma segmentação", "", "warning");

			if (this.segmentationId != this.selectedSegmentation && this.segmentationId) {
				this.$emit(event, this.segmentationId);
			}

			this.editable = false;
			this.create = false;
		},
		max_date(final_date) {
			if (final_date !== null) {
				return final_date;
			}
			return this.today();
		},
		min_date(initial_date) {
			if (initial_date !== null) {
				return initial_date;
			}

			return new Date(0);
		},
		today() {
			return new Date();
		}
	},
	watch: {
		segmentation: {
			handler() {
				this.filterSegmentation();
			},
			deep: true
		},
		create: {
			handler(value) {
				if (value) {
					this.getSegmentationData();
				}
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.disabled {
	pointer-events: none;
}

.input-floating {
	position: relative;

	.form-control {
		padding-top: 0.475rem;
		padding-bottom: 0.475rem;
	}

	.date-input {
		padding-top: 0.275rem !important;
		max-height: 36px;
	}

	.form-control.is-invalid + label {
		color: #dc3545;
	}

	label {
		transition: 100ms;
		background: transparent;
		position: absolute;
		padding: 0 0.5rem;
		font-size: 1rem;
		line-height: 1rem;
		margin: 0 !important;
		top: 50%;
		left: 0.5rem;
		transform: translateY(-50%);
		max-width: 90%;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.form-control:focus + label,
	.form-control:not(:placeholder-shown) + label {
		left: 0.3rem;
		top: -20%;
		transform: translateY(0);
		background: white;
	}
}

.form-input:disabled {
	background: white;
}
</style>
