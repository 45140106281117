<template>
	<section class="my-4">
		<section>
			<div class="row">
				<div class="col">
					<div class="form-group">
						<label class="m-0">Número</label>
						<input
							type="text"
							:value="formatPhone(value.phone)"
							class="form-control border-0 font-weight-bold"
							readonly
							v-b-toggle="`phone-${uuid}`"
						/>
					</div>
				</div>

				<div class="col">
					<div class="d-flex flex-column">
						<label class="m-0" for="input-start">Início</label>

                        <validation-provider
                            #default="{ failed }"
                            rules="required"
                        >
                            <date-picker
                                placeholder="Selecione uma data"
                                prefix-class="xmx"
                                class="w-100"
                                type="datetime"
                                @pick="autoScroll($event)"
                                format="DD/MM/YYYY HH:mm"
                                value-type="YYYY-MM-DD HH:mm"
                                :lang="datePickerPt"
                                v-model="value.begin_date"
                                :show-second="false"
                                :input-class="{ 'mx-input': true, 'border-danger': failed }"
                                :disabled-date="(date) => disableDateTime(date, value.finish_date, 'max')"
                                :disabled-time="(date) => disableDateTime(date, value.finish_date, 'max', true)"
                            ></date-picker>
                        </validation-provider>
                    </div>
                </div>

				<div class="col">
					<div class="d-flex flex-column">
						<label class="m-0" for="input-start">Final</label>

                        <validation-provider
                            #default="{ failed }"
                            rules="required"
                        >
                            <date-picker
                                placeholder="Selecione uma data"
                                prefix-class="xmx"
                                class="w-100"
                                type="datetime"
                                @pick="autoScroll($event)"
                                format="DD/MM/YYYY HH:mm"
                                value-type="YYYY-MM-DD HH:mm"
                                :lang="datePickerPt"
                                :input-class="{ 'mx-input': true, 'border-danger': failed }"
                                v-model="value.finish_date"
                                :show-second="false"
                                :disabled-date="(date) => disableDateTime(date, value.begin_date, 'min')"
                                :disabled-time="(date) => disableDateTime(date, value.begin_date, 'min', true)"
                            ></date-picker>
                        </validation-provider>
                    </div>
                </div>

				<div class="col d-flex align-items-end">
					<div class="form-group">
						<input
							:value="score.rating"
							:class="`${
								!['Excelente', 'Ótimo', 'Bom'].includes(score.rating)
									? 'bag-danger'
									: 'bag-success'
							} rounded p-1 text-center p-2`"
							disabled
						/>
					</div>
				</div>
				<div class="d-flex justify-content-end align-items-center">
					<span class="p-2" v-b-toggle="`phone-${uuid}`">
						<font-awesome-icon
							:icon="`fa-sharp fa-solid ${
								collapsed ? 'fa-chevron-up' : 'fa-chevron-down'
							}`"
						/>
					</span>
				</div>
			</div>
		</section>

		<b-collapse v-model="collapsed" :id="`phone-${uuid}`">
			<div class="row">
				<div class="col-12 py-4">
					<div class="row">
						<div class="col-12 d-flex flex-row text-left">
							<b-form-checkbox
								class="mb-2"
								name="check-button"
								size="lg"
								switch
								v-model="value.is_custom"
							>
								Calendário Personalizado
							</b-form-checkbox>
						</div>
						<div class="col-12">
							<custom-calendar v-model="value.calendar" />
						</div>
					</div>
				</div>
				<div class="col-12 col-lg-6 py-2">
					<div class="d-flex flex-column overflow-hidden">
						<h6 class="text-black text-left font-weight-bold">
							<span v-if="progressCampaigns.length > 0">
								Este número contém outras campanhas em andamento/programadas.
							</span>
							<span v-else>
								Este número não contém outras campanhas em andamento/programadas.
							</span>
							<span
								v-if="loading"
								class="spinner-border spinner-border-sm mx-2"
								role="status"
							></span>
							<a
								v-b-modal="`adjustShotPhoneModal-${uuid}`"
								v-if="progressCampaigns.length > 0"
								class="mx-2"
							>
								Ajustar
							</a>
							<adjust-shot-phone-modal
								v-if="progressCampaigns.length >= 0"
								v-model="progressCampaigns"
								:phone-id="value.phone_id"
								:id="`adjustShotPhoneModal-${uuid}`"
								@update="getCampaignsByPhone()"
							/>
						</h6>
						<div v-if="progressCampaigns.length > 0">
							<ul class="list-group mt-2" style="list-style: none">
								<li
									v-for="(campaign, i) in progressCampaigns"
									:key="`campaign-progress-${i}`"
									class="text-black-50 text-left"
								>
									<p class="mb-1">
										<b>{{ campaign.name }}</b>
										- De:
										<b>
											{{
												moment(campaign.begin_date).format(
													"DD/MM/YYYY HH:mm"
												)
											}}
										</b>
										Até:
										<b>
											{{
												moment(campaign.finish_date).format(
													"DD/MM/YYYY HH:mm"
												)
											}}
										</b>
									</p>
								</li>
							</ul>
						</div>
						<hr />
					</div>
				</div>
				<div class="col-12 col-lg-6 d-flex justify-content-lg-end py-2">
					<div class="d-flex flex-column">
						<h5
							class="text-black font-weight-bolder m-0 mr-2"
							v-b-tooltip.hover.left="
								'Esse número é uma estimativa, considerando os clientes que ainda não receberam nenhum disparo e também outras campanhas no mesmo período.'
							"
						>
							Taxa de Disparos:
							<strong class="text-success font-weight-bolder m-0">
								{{ score.per_hour }}
							</strong>
							<strong class="text-secondary font-weight-light">por hora</strong>
						</h5>
						<h6 class="mt-2">Whatsapp</h6>
					</div>
				</div>
			</div>
		</b-collapse>
	</section>
</template>

<script>
import CustomCalendar from "../../shots-patterns/components/CustomCalendar.vue";
import { customCalendarModel, datePickerPt } from "@/views/pages/dashboard/campaigns/constants";
import DatePicker from "vue2-datepicker";
import { BCollapse, BFormCheckbox } from "bootstrap-vue";
import api from "@/api";
import { uuid } from "vue-uuid";
import { ValidationProvider } from "vee-validate";
import { required } from "@/@core/utils/validations/validations";
import { formatPhone, disableDateTime, autoScroll } from "@/api/functions";
import AdjustShotPhoneModal from "./AdjustShotPhoneModal.vue";

export default {
	components: {
		BCollapse,
		DatePicker,
		CustomCalendar,
		BFormCheckbox,
		ValidationProvider,
		AdjustShotPhoneModal
	},
	emits: ["input"],
	props: {
		value: {
			type: Object,
			default: () => {
				return {
					phone_id: null,
					phone: "+00 (00) 00000-0000",
					calendar: null,
					begin_date: null,
					finish_date: null,
					is_custom: false
				};
			}
		},
		segmentationId: {
			type: String
		},
		campaignId: {
			type: String,
			default: null
		}
	},
	data() {
		return {
			datePickerPt,
			required,
			uuid: uuid.v4(),
			score: {
				per_hour: 0,
				rating: "Em análise"
			},
			timeout: null,
			campaignsTimeout: null,
			collapsed: false,
			progressCampaigns: [],
			loading: false
		};
	},
	watch: {
		"$props.value.is_custom"(val) {
			let schedule = this.$props.value;
			if (val) {
				schedule.calendar = Object.assign(
					{},
					schedule.calendar == null ? customCalendarModel : schedule.calendar
				);
			} else {
				schedule.calendar = null;
			}
			this.$emit("input", schedule);
		},
		"$props.value.begin_date"() {
			this.getCampaignsByPhone();
		},
		"$props.value.finish_date"() {
			this.getCampaignsByPhone();
		},
		"$props.value": {
			handler() {
				clearTimeout(this.timeout);
				this.timeout = setTimeout(() => this.calcShotScore(), 1000);
			},
			deep: true
		},
		score: {
			handler(val) {
				let schedule = this.$props.value;
				schedule.tax = val && val.per_hour ? val.per_hour : 0;
				this.$emit("input", schedule);
			},
			deep: true
		},
		collapsed(val) {
			if (val && this.progressCampaigns.length === 0) {
				this.getCampaignsByPhone();
			}
		}
	},
	created() {
		this.calcShotScore();
	},
	methods: {
		autoScroll,
		disableDateTime,
		formatPhone,
		calcShotScore() {
			const { begin_date, finish_date, phone_id, calendar } = this.value;

			if (begin_date && finish_date) {
				api.post("/calculator", {
					begin_date: begin_date,
					finish_date: finish_date,
					segmentation_id: this.segmentationId,
					campaign_id: this.campaignId,
					phone_id: phone_id,
					calendar: calendar
				})
					.then((response) => {
						const { body, type } = response.data;

						if (type === "success") {
							return Object.assign(this.score, body);
						}

						throw response;
					})
					.catch(() => {
						return Object.assign(this.score, {
							per_hour: 0,
							rating: "Em análise"
						});
					});
			} else {
				Object.assign(this.score, {
					per_hour: 0,
					rating: "Em análise"
				});
			}
		},
		getCampaignsByPhone() {
			clearTimeout(this.campaignsTimeout);

			this.campaignsTimeout = setTimeout(() => {
				this.loading = true;
				api.get(`/shots/${this.value.phone_id}/campaigns`, {
					params: {
						begin_date: this.$props.value.begin_date,
						finish_date: this.$props.value.finish_date
					}
				})
					.then((res) => {
						const { body, type } = res.data;

						if (type === "success") {
							return (this.progressCampaigns = body);
						}
						throw res;
					})
					.catch(() => {
						this.progressCampaigns = [];
					})
					.finally(() => (this.loading = false));
			}, 500);
		}
	}
};
</script>

<style lang="scss">
.bag-danger {
	background: #f5b09d;
}

.bag-success {
	background: #b8e8b9;
	border: #35aa28;
	color: black;
}

.bag-intro {
	background: #e8e4b8;
	border: #aaa428;
	color: black;
}
</style>
