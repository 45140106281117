<template>
    <section id="dashboard-new-campaign">
        <campaign
            :data="campaign"
            :editable="campaign.editable"
            :loading="loading"
            @onContinue="() => saveCampaign(true)"
            @onEdit="campaign.editable = true"
            @onSave="() => saveCampaign(false)"
        />
        <segmentation
            v-if="campaign._id != null && !onlyCampaign"
            :loading="loading"
            :selectedSegmentation="campaign.segmentation_id"
            class="mt-5"
            id="segmentation"
            :blockChange="Boolean(campaign.shot_id)"
            @onSave="(id)=> {campaign.segmentation_id = id; saveCampaign(false, 'shot')}"
            @onContinue="(id)=> {campaign.segmentation_id = id; saveCampaign(true, 'shot')}"
        />
        <shot
            v-if="campaign._id != null && campaign.segmentation_id != null && !onlyCampaign"
            :campaign-id="campaign._id"
            :segmentation-id="campaign.segmentation_id"
            :shot-id="campaign.shot_id"
            id="shot"
            class="mt-5"
        />
    </section>
</template>

<script>
import api from '@/api';
import Campaign from './Campaign.vue';
import Segmentation from './Segmentation.vue';
import Shot from './Shot.vue';

export default {
    components: {
        Campaign,
        Segmentation,
        Shot
    },
    data() {
        return {
            campaign: {
                name: "",
                type: "divulgation",
                creative: null,
                campaign_texts: [],
                attachments: [],
                answers: [],
                deleted_answers: [],
                answers_frequency: null,
                segmentation_id: null,
                shot_id: null,
                editable: true
            },
            segmentation: null,
            shot: null,
            loading: false
        }
    },
    created() {
        if (this.$route.name.includes('edit')) {
            this.getCampaign(this.$route.params.id);
        }
    },
    computed: {
        onlyCampaign() {
            return String(this.$route.name).includes('contents/edit')
        }
    },
    methods: {
        toast(message, title, type = 'danger') {
            if (type === 'error') type = 'danger';
            this.$bvToast.toast(message, {title, variant: type})
        },
        /**
         * Persiste os dados da campanha.
         */
        async saveCampaign(next = true, field = 'segmentation') {
            this.loading = true;

            const data = Object.fromEntries([
                ...Object.entries(this.campaign).filter(([key]) => ["name", "type", "campaign_texts", "segmentation_id", "shot_id"].includes(key)),
                ['creative', this.campaign.creative == null ? null : this.campaign.creative.path]
            ]);

            try {
                const response = await (!this.campaign._id
                    ? api.post('/campaigns', data)
                    : api.put(`/campaigns/${this.campaign._id}`, data));

                const {type, message, body} = response.data;

                if (type === 'success') {
                    const isNew = this.campaign._id == null
                    Object.assign(this.campaign, body);

                    // const result = this.saveCampaignFiles();

                    if (await this.saveCampaignAnswers())
                        this.campaign.editable = false;

                    if (isNew) {
                        await this.$router.replace({name: 'campaigns/edit', params: {id: body._id}})
                    }

                    if (next) {
                        document.querySelector(`#${field}`).scrollIntoView({behavior: 'smooth'});
                    } else {
                        field === 'segmentation'
                            ? await this.$router.replace({name: 'campaigns/contents'})
                            : await this.$router.replace({name: 'segmentation'})
                    }
                    this.$forceUpdate();
                }

                this.toast(message, 'Campanhas', type);
            } catch (error) {
                let message = "Não foi possível salvar a campanha.";
                if (error.response)
                    message = error.response.data.message;
                this.toast(message, 'Campanhas');
            } finally {
                this.loading = false
            }
        },
        /**
         * Persiste as respostas automáticas da campanha.
         */
        async saveCampaignAnswers() {
            if (this.campaign.type !== 'interaction' || this.campaign.answers == null) return true;

            const promises = [
                ...this.campaign.answers.map(async (answer, i) => {
                    const data = {
                        neddles: answer.neddles,
                        answers: answer.answers,
                        frequency: this.campaign.answers_frequency,
                        campaign_interaction_id: this.campaign._id
                    }

                    const response = await (!answer._id
                        ? api.post('/answers', data)
                        : api.put(`/answers/${answer._id}`, data));

                    const {type, body, message} = response.data;

                    if (type === 'success') {
                        return Object.assign(this.campaign.answers[i], body);
                    }
                    throw message;
                }),
                ...this.campaign.deleted_answers.map(async (answer, i) => {
                    const response = await api.delete(`/answers/${answer._id}`);

                    const {type, message} = response.data;

                    if (type === 'success') {
                        return this.campaign.deleted_answers.splice(i, 1);
                    }
                    throw message;
                })
            ]

            try {
                await Promise.all(promises);
                this.toast('Respostas automáticas salvas com sucesso.', 'Respostas Automáticas', 'success');
                return true;
            } catch (error) {
                this.toast('Algumas respostas não foram configuradas corretamente.', 'Respostas Automáticas', 'danger');
                return false;
            }
        },
        /**
         * Persiste os arquivos da campanha, como imagem criativo e anexos.
         */
        // async saveCampaignFiles() {
        //     const formData = new FormData();
        //     formData.append('_method', 'PUT');

        //     if (this.campaign.creative instanceof File) {
        //         formData.append('creative', this.campaign.creative);
        //     }
        //     if (this.campaign.attachments.length > 0) {
        //         this.campaign.attachments.forEach((e, i) => {
        //             if (e instanceof File) {
        //                 formData.append(`attachments[${i}]`, e);
        //             }
        //         })
        //     }

        //     try {
        //         const response = await api.post(`/campaigns/${this.campaign._id}`, formData);
        //         const {type, body} = response.data;

        //         if (type == 'success') {
        //             Object.assign(this.campaign, body);
        //             return true;
        //         }
        //         throw 'Não foi possível salvar as imagens corretamente';
        //     } catch (error) {
        //         this.toast('As imagens e anexos não foram salvos corretamente.', 'Campanhas', 'danger');
        //         return false;
        //     }
        // },
        getCampaign(id) {
            this.loading = true;

            api
                .get(`/campaigns/${id}`)
                .then(res => {
                    const {message, type, body} = res.data;

                    if (type === 'success') {
                        Object.assign(this.campaign, {...body, editable: false});

                        if (body['answers'] != null && body['answers'].length > 0) {
                            this.campaign.answers_frequency = body['answers'][0].frequency || null;
                        }
                        return;
                    }
                    throw message
                })
                .catch(error => {
                    console.log(error);
                    let message = 'Não foi possível carregar campanha';
                    if (error.response) {
                        message = error.response.data.message;
                    }
                    this.toast(message, 'Campanhas');
                    this.$router.replace({name: 'campaigns/new'});
                })
                .finally(() => this.loading = false);
        },
    }
}
</script>
