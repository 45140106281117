<template>
    <div class="preview position-relative">
        <img src="@/assets/images/pages/phone.png" class="preview-phone">

        <div class="rounded preview-notification">
            <div class="d-flex align-items-start">
                <div class="bg-success icon">
                    <font-awesome-icon icon="fa-brands fa-whatsapp" />
                </div>
                <div class="text-left" style="width: 82%">
                    <div class="d-flex justify-content-between align-items-center">
                        <p class="m-0">WHATSAPP</p>
                        <small>now</small>
                    </div>
                    <b>
                        {{ name || "Bianca" }}
                    </b>
                    <p class="title font-weight-bold my-1">
                        {{
                            title || "Lorem ipsum dolor sit amet"
                        }}
                    </p>
                    <p class="text m-0">
                        {{
                            text || "Natus reiciendis dicta obcaecati maxime ipsam ratione doloribus, enim soluta blanditiis esse error sit nisi quos eveniet libero et earum saepe."
                        }}
                    </p>
                </div>
            </div>
        </div>

        <div class="rounded preview-data">
            <img v-if="imageSrc" class="rounded preview-image" :src="imageSrc">
            <div v-else class="d-flex align-items-center rounded preview-image">
                <p class="h3">Novo criativo</p>
            </div>

            <div class="my-3">
                <p class="title font-weight-bold mb-2">
                    {{
                        title || "Lorem ipsum dolor sit amet"
                    }}
                </p>
                <p class="text">
                    {{
                        text || "Natus reiciendis dicta obcaecati maxime ipsam ratione doloribus, enim soluta blanditiis esse error sit nisi quos eveniet libero et earum saepe."
                    }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['name', 'title', 'text', 'imageSrc'],
}
</script>

<style lang="scss" scoped>
.preview {
    aspect-ratio: 164/324;
    width: 200px;
    height: 400px;

    * {
        z-index: 2;
    }

    .preview-phone {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        object-fit: cover;
        z-index: 1 !important;
    }

    .preview-notification {
        overflow: hidden;
        position: absolute;
        background: white;
        top: 8%;
        left: 10%;
        right: 10%;
        font-size: 0.6rem;
        line-height: 0.6rem;
        padding: 5px;

        .title, .text {
            font-size: 0.65rem;
            line-height: 0.65rem;
            text-overflow: ellipsis;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
        }
        .text {
            font-size: 0.55rem;
        }

        .icon {
            aspect-ratio: 1;
            color: white !important;
            padding: 2px;
            width: 19px;
            height: 19px;
            border-radius: 2px;
            margin-right: 3px;

            svg {
                width: 15px;
                height: 15px;
            }
        }
    }

    .preview-data {
        overflow: hidden;
        position: absolute;
        background: white;
        bottom: 10%;
        left: 10%;
        right: 10%;
        font-size: 1rem;
        line-height: 1rem;
        padding: 5px;

        .preview-image {
            aspect-ratio: 1;
            width: 150px;
            height: 150px;
            background-color: #CCC;
            object-fit: cover;
            object-position: center;
        }

        .title, .text {
            font-size: 0.8rem;
            line-height: 0.8rem;
            text-overflow: ellipsis;
            width: 100%;
            white-space: break-word;
            -webkit-line-clamp: 2;
            max-height: 1.6rem;
            text-align: left;
            overflow: hidden;
        }
    }
}
</style>