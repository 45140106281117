<template>
    <validation-observer ref="campaignValidation">
        <b-card class="position-relative p-5" no-body>
            <loading v-if="loading"/>
            <section>
                <h3 class="text-left text-secondary font-weight-bolder mb-2">
                    {{ campaign._id ? "Editar Campanha" : 'Nova Campanha' }}
                </h3>
                <div class="row mt-3">
                    <div class="form-group col-12 col-md-6 col-lg-8 text-left">
                        <validation-provider
                            #default="{ errors }"
                            name="name"
                            rules="required"
                        >
                            <label for="campaignInputName">Nome</label>
                            <input id="campaignInputName" v-model="campaign.name"
                                   :class="{'is-invalid': errors.length > 0}" :disabled="!editable"
                                   class="form-control" required type="text">
                        </validation-provider>
                    </div>
                    <div class="form-group col-12 col-md-6 col-lg-4 text-left">
                        <label for="campaignInputType">Tipo</label>
                        <select id="campaignInputType" v-model="campaign.type" :disabled="!editable"
                                class="form-control"
                                name="type">
                            <option selected value="divulgation">Divulgação</option>
                            <option value="interaction">Interação</option>
                        </select>
                    </div>
                </div>
            </section>

            <hr class="my-4">

            <section class="d-flex flex-column flex-md-row justify-content-between align-items-start">
                <div class="mb-2">
                    <h3 class="text-left text-secondary font-weight-bolder mb-4">
                        Criativo
                    </h3>

                    <div class="d-flex mb-2">
                        <resizable-file-input
                            :disabled="!editable"
                            :options="{'aspect-ratio': 1}"
                            @input="(e) => campaign.creative = e"
                        >
                            <template v-slot:preview>
                                <div
                                    class="m-0 rounded pick-image d-flex align-items-center justify-content-center position-relative"
                                >
                                    <div
                                        v-if="!campaign.creative && editable"
                                        class="d-flex align-items-center"
                                    >
                                        <span class="circle-icon mr-3">+</span>
                                        <span>Adicionar criativo</span>
                                    </div>

                                    <img
                                        v-if="campaign.creative"
                                        :src="campaign.creative.thumbnail"
                                        class="preview-image rounded"
                                        alt=""
                                    >

                                    <span
                                        v-if="campaign.creative && editable"
                                        class="close-button d-flex justify-content-center align-items-center border rounded-circle"
                                        @click.prevent.stop="campaign.creative = null"
                                    >
                                        <font-awesome-icon icon="fa-solid fa-close" size="sm"/>
                                    </span>
                                </div>
                            </template>
                        </resizable-file-input>
                    </div>
                </div>

                <preview
                    :image-src="!campaign.creative ? null : campaign.creative.thumbnail"
                    :name="$store.state.user.user.name"
                    :text="campaign.campaign_texts.length === 0 ? null : campaign.campaign_texts[0].text"
                    :title="campaign.campaign_texts.length === 0 ? null : campaign.campaign_texts[0].title"
                />
            </section>

            <hr class="my-4">

            <div class="d-flex justify-content-between">
                <div>
                    <h3 class="text-left text-secondary font-weight-bolder m-0">
                        {{ campaign.type === 'interaction' ? 'Pergunta Inicial' : 'Textos da Campanha' }}
                    </h3>
                </div>
                <div class="d-flex align-items-center">
                    <h6 class="text-secondary font-weight-bolder m-0 mr-2">
                        Total de Variações:
                    </h6>
                    <h4 class="text-blue font-weight-bolder m-0">
                        {{ variationCount }}
                    </h4>
                </div>
            </div>
            <p class="text-left mb-2">Insira de 3 a 6 textos diferentes para sua campanha. </p>

            <tree-root :no-header="true" :no-padding="campaign.type !== 'interaction' || !hideAutomaticAnswer">
                <tree-branch>
                    <div class="row">
                        <div v-for="(_, index) in campaign.campaign_texts"
                             :key="`campaign-text-container-${index}`"
                             class="col-12 col-md-6">
                            <card-text-synonym
                                :data="campaign.campaign_texts[index]"
                                :index="index + 1"
                                :read-only="!editable"
                                @onDelete="removeCampaignText(index)"
                                allow-attachments
                                :max-attachments="3"
                            />
                        </div>
                        <div v-if="editable" class="col-12 col-md-6">
                            <validation-provider
                                #default="{ errors }"
                                name="campaign_text"
                                rules="required"
                            >
                                <input :value="campaign.campaign_texts.length > 0 ? 'valido' : ''" hidden type="text">
                                <div
                                    :class="{ 'border-danger': errors.length > 0, 'text-danger': errors.length > 0 }"
                                    class="add-campaign-text rounded d-flex align-items-center justify-content-center px-2"
                                    @click="addCampaignText"
                                >
                                    <div class="d-flex align-items-center flex-wrap justify-content-center">
                                        <span class="circle-icon mr-3">+</span>
                                        <span>Adicionar uma variação de texto</span>
                                    </div>
                                </div>
                            </validation-provider>
                        </div>
                    </div>

                    <div v-if="campaign.type === 'interaction'">
                        <div class="row mt-5 mb-4 px-1">
                            <div class="col-auto d-flex justify-content-start align-items-center my-1">
                                <b-form-checkbox
                                    v-model="hideAutomaticAnswer"
                                    :class="hideAutomaticAnswer ? 'text-blue' : ''"
                                    name="check-button"
                                    size="lg"
                                    switch
                                    :disabled="!editable"
                                >
                                    Habilitar Respostas Automáticas
                                </b-form-checkbox>
                            </div>
                            <div class="col-12 col-md-6 col-lg-4 my-1">
                                <b-form-select v-model="campaign.answers_frequency" :disabled="!editable">
                                    <b-form-select-option :value="null" disabled>Selecione uma opção
                                    </b-form-select-option>
                                    <b-form-select-option value="suggest">Sugerir para o Atendente
                                    </b-form-select-option>
                                    <b-form-select-option value="always">Sempre</b-form-select-option>
                                    <b-form-select-option value="exactly">Apenas respostas exatas</b-form-select-option>
                                </b-form-select>
                            </div>
                        </div>
                    </div>
                </tree-branch>
                <campaign-recursive-answer
                    v-for="(answer, i) in campaign.answers"
                    :id="answer._id"
                    :key="`campaign-sequential-answer-${i}`"
                    :data="answer"
                    @delete="() => removeCampaignAnswer(answer, i)"
                    :editable="editable"
                />
                <tree-branch v-if="campaign.type === 'interaction' && editable && hideAutomaticAnswer">
                    <template slot="control-button">
                        <span
                            class="d-flex align-items-center"
                            role="button"
                            @click="() => addSequentialAnswer(campaign.answers)"
                        >
                            <span class="circle-icon mr-2">
                                <font-awesome-icon icon="fas fa-plus" size="xs"/>
                            </span>
                            Adicionar outra condição de resposta
                        </span>
                    </template>
                </tree-branch>
            </tree-root>

            <div>
                <div v-if="editable" class="row align-items-center justify-content-end mt-5">
                    <div class="col-12 col-md-8 col-lg-6 my-1 d-flex flex-wrap">
                        <button class="btn flex-grow-1 btn-outline-primary m-1 text-nowrap"
                                @click="cancel">
                            Cancelar
                        </button>
                        <button class="btn flex-grow-1 btn-outline-primary m-1 text-nowrap"
                                @click="handleSave('onSave')">Apenas
                            Salvar
                        </button>
                        <button
                            v-if="!String(this.$route.name).includes('contents/edit')"
                            class="btn flex-grow-1 btn-primary m-1 text-nowrap"
                            @click="handleSave('onContinue')"
                        >
                            Segmentar Campanha
                        </button>
                        <button
                            v-else
                            class="btn flex-grow-1 btn-primary m-1 text-nowrap"
                            @click="$router.go(-1)"
                        >
                            Voltar
                        </button>
                    </div>
                </div>

                <div v-else class="row align-items-center justify-content-end mt-5">
                    <div class="col-12 col-md-4 col-lg-3 col-xl-2 my-1 d-flex flex-wrap">
                        <button class="btn flex-grow-1 btn-outline-primary m-1 text-nowrap" @click="$emit('onEdit')">
                            Editar
                        </button>
                    </div>
                </div>
            </div>
            <short-link-modal />
        </b-card>
    </validation-observer>
</template>

<script>

import {BCard, BFormCheckbox, BFormSelect, BFormSelectOption} from 'bootstrap-vue';
import {ValidationProvider, ValidationObserver} from 'vee-validate';
import Preview from '../components/Preview.vue';
import Loading from '@/views/components/Loading.vue';
import CampaignRecursiveAnswer from "../components/CampaignRecursiveAnswer.vue";
import ResizableFileInput from '@/views/components/ResizableFileInput.vue';
import {required} from '@/@core/utils/validations/validations';
import TreeBranch from '@/views/components/TreeLayout/TreeBranch';
import TreeRoot from '@/views/components/TreeLayout/TreeRoot';
import CardTextSynonym from '@/views/components/CardTextSynonym/index.vue';
import ShortLinkModal from '@/views/pages/dashboard/messages/modals/ShortLinkModal.vue';

export default {
    components: {
        CampaignRecursiveAnswer,
        BCard,
        Preview,
        CardTextSynonym,
        BFormCheckbox,
        BFormSelect,
        BFormSelectOption,
        ResizableFileInput,
        ValidationProvider,
        ValidationObserver,
        Loading,
        TreeBranch,
        TreeRoot,
        ShortLinkModal
    },
    props: ['data', 'editable', 'loading'],
    data() {
        return {
            required,
            campaign: this.data,
            selected: null,
            hideAutomaticAnswer: this.$props.data.answers != null && this.$props.data.answers.length > 0,
            variationCount: 0,
        }
    },
    methods: {
        addCampaignText() {
            this.campaign.campaign_texts.push({
                title: "Adicionar título",
                text: "Adicionar mensagem",
                attachments: [],
                synonyms: []
            });
        },
        cancel() {
            this.$router.replace({name: 'campaigns/contents'})
        },
        imageSrc(image) {
            try {
                if (image instanceof File)
                    return URL.createObjectURL(image);
                return image;
            } catch (error) {
                return null;
            }
        },
        removeCampaignText(index) {
            this.campaign.campaign_texts.splice(index, 1)
            this.$forceUpdate()
        },
        handleSaveAnswer() {
            this.$swal('Respostas Salvas Com Sucesso!', 'Todos as respostas já estão em seu banco. ', 'success');
        },
        handleSave(event) {
            this.$refs.campaignValidation.validate().then((success) => {
                if (success) this.$emit(event);
            })
        },
        addSequentialAnswer(array) {
            array.push({
                neddles: [],
                answers: [{
                    condition: "Resposta",
                    text: 'Adicionar réplica',
                    attachments: [],
                    synonyms: [],
                    neddles: []
                }]
            });
        },
        removeCampaignAnswer(answer, i) {
            this.campaign.answers.splice(i, 1);
            this.campaign.deleted_answers.push(answer);
        }
    },
    watch: {
        hideAutomaticAnswer(val) {
            if (!val && this.campaign.answers.length > 0) {
                this.$swal({
                    title: "Deseja remover todas as perguntas sequenciais?",
                    showCancelButton: true,
                    cancelButtonText: "Não",
                    confirmButtonText: "Sim",
                    confirmButtonColor: "#317f98",
                })
                    .then(result => {
                        if (result.isConfirmed) {
                            this.campaign.deleted_answers = JSON.parse(JSON.stringify(this.campaign.answers))
                            return this.campaign.answers = [];
                        }
                        this.hideAutomaticAnswer = true;
                    })
            }
        },
        campaign: {
            handler(value) {
                if (!this.hideAutomaticAnswer) {
                    this.hideAutomaticAnswer = value.answers && value.answers.length > 0;
                }
                const campaignTexts = value.campaign_texts;
                let count = 0;
                campaignTexts.forEach(campaignText => {
                    let variation = 1;
                    campaignText.synonyms.forEach(synonym => {
                        variation *= synonym.synonyms.length + 1;
                    })
                    count += variation;
                });
                this.variationCount = count;
            },
            deep: true,
        }
    }
}
</script>

<style scoped lang="scss">

.pick-image {
    aspect-ratio: 1;
    width: 100vw !important;
    height: 100vw !important;
    max-height: 300px;
    max-width: 300px;
    border: 1px solid #EEE;
    cursor: pointer;

    .close-button {
        position: absolute;
        z-index: 10;
        top: -0.75rem;
        right: -0.75rem;
        background-color: white;
        border-color: #DDD;
        width: 1.5rem;
        height: 1.5rem;

        svg {
            width: 100%;
            aspect-ratio: 1;
        }
    }

    .preview-image {
        width: 100vw !important;
        height: 100vw !important;
        max-height: 300px;
        max-width: 300px;
        object-fit: cover;
        object-position: center;
    }
}
</style>
