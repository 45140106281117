<template>
	<b-card class="position-relative p-5" no-body>
		<loading v-if="loading" />
		<section>
			<h3 class="text-left text-secondary font-weight-bolder mb-2">Disparar</h3>

			<div class="row mt-3">
				<div
					class="form-group d-flex align-items-center col-12 col-md-6 col-lg-8 text-left"
				>
					<v-select
						v-model="shot.pattern_id"
						:disabled="creating"
						:options="patternOptions"
						placeholder="Selecionar um modelo de disparo"
						class="flex-grow-1"
						:reduce="pattern => pattern.code"
					>
						<span slot="no-options">Desculpe, não há opções correspondentes.</span>
					</v-select>
				</div>
				<div class="form-group col-12 col-md-6 col-lg-4 d-flex">
					<template v-if="!creating">
						<button
							class="btn btn-outline-primary flex-grow-1 mr-2"
							@click="creating = true"
						>
							Criar novo
						</button>
						<button class="btn btn-outline-primary flex-grow-1" @click="cancel">
							Cancelar
						</button>
					</template>
					<button
						v-else
						class="btn btn-outline-primary flex-grow-1"
						@click="creating = false"
					>
						Cancelar
					</button>
				</div>
			</div>
		</section>

		<shot-pattern
			@created="patternCreated"
			v-if="creating"
			:is-card="false"
			class="mt-5 mb-4"
		/>

		<section class="text-left mb-4 mt-5" v-else>
			<h5 class="text-dark font-weight-bolder m-0 mb-2">Horários de Disparo</h5>

			<validation-observer ref="shotValidationForm" tag="form">
				<div v-if="shot.schedules.length > 0">
					<phone-shot-row
						v-for="(_, i) in shot.schedules"
						:key="`phone-row-${i}`"
						v-model="shot.schedules[i]"
						:segmentation-id="segmentationId"
						:campaign-id="campaignId"
					/>
				</div>
				<div v-else>
					<p class="m-0">Nenhum número de disparo encontrado a partir da segmentação.</p>
				</div>
			</validation-observer>

			<section class="mt-5">
				<validation-observer ref="testShotValidationForm" tag="form">
					<div class="d-flex flex-column">
						<h6 class="text-black text-left font-weight-bold mb-2">
							Teste Rápido de disparo
						</h6>
						<div class="d-flex flex-row align-items-start text-left mt-4">
							<validation-provider
								#default="{ failed, valid }"
								rules="required|min:17"
							>
								<input
									v-model="testPhone"
									class="form-control w-100"
									:class="{ 'is-valid': valid, 'is-invalid': failed }"
									placeholder="+55 (99) 99999-9999"
									type="tel"
									v-mask="['+## (##) #####-####', '+## (##) ####-####']"
								/>
								<div class="invalid-feedback">Formato do número errado</div>
							</validation-provider>

							<b-button
								class="font-weight-bold ml-4"
								variant="primary"
								@click="testShot"
							>
								Disparar teste
							</b-button>
						</div>
						<hr />
					</div>
				</validation-observer>
			</section>

			<div class="row align-items-center justify-content-end mt-5">
				<div class="col-12 col-md-4 col-lg-3 col-xl-2 my-1 d-flex flex-wrap">
					<div id="schedule-shot-button" class="flex-grow-1 m-1">
						<button :disabled="Boolean(blockButton)" @click="submit" class="btn btn-primary text-nowrap w-100">
							{{ shotId ? "Reagendar Disparo" : "Agendar Disparo" }}
						</button>
					</div>
					<b-tooltip v-if="blockButton" target="schedule-shot-button" triggers="hover">
						{{ blockButton }}
					</b-tooltip>
				</div>
			</div>
		</section>
	</b-card>
</template>

<script>
import { BCard, BButton, BTooltip } from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import vSelect from "vue-select";
import moment from "moment";

import api from "@/api";
import PhoneShotRow from "../components/PhoneShotRow.vue";
import { min, required } from "@/@core/utils/validations/validations";
import Loading from "@/views/components/Loading.vue";
import ShotPattern from "../../shots-patterns/ShotPattern.vue";
import { formatPhone } from "@/api/functions";

moment.locale("pt");

export default {
	components: {
		BCard,
		BButton,
		BTooltip,
		PhoneShotRow,
		ValidationProvider,
		ValidationObserver,
		Loading,
		ShotPattern,
		vSelect
	},
	props: {
		campaignId: String,
		segmentationId: String,
		shotId: String
	},
	data() {
		return {
			testPhone: null,
			shotsPatterns: [],
			phones: [],
			loading: false,
			creating: false,
			required,
			min,
			shot: {
				_id: this.$props.shotId,
				schedules: [],
				pattern_id: null,
				campaign_id: this.$props.campaignId,
			}
		};
	},
	watch: {
		"$props.shotId"(val) {
			if (val == null) this.resetShot();
		},
		"$props.segmentationId"(newVal, oldVal) {
			if (newVal !== oldVal && this.$props.shotId == null) this.resetShot();
		},
		"shot.pattern_id"(val) {
			if (!this.loading) this.useShotPattern(val);
		},
		creating(val) {
			if (!val) this.getShotsPatterns();
		}
	},
	created() {
		let promises = [this.getShotsPatterns()];

		if (this.shot._id) promises.push(this.getShot());
		else promises.push(this.getPhones());

		this.loading = true;
		Promise.all(promises).finally(() => (this.loading = false));
	},
	computed: {
		patternOptions() {
			return this.shotsPatterns.map((e) => ({ label: e.name, code: e._id }), []);
		},
		blockButton() {
			if (this.shot.schedules.length === 0)
				return "Nenhum número de disparo associado";

			let invalidDate = false;
			
			for (let i = 0; i < this.shot.schedules.length; i++) {
				const schedule = this.shot.schedules[i];
				
				if (schedule.tax && schedule.tax > 180)
					invalidDate = "Para sua segurança, não podemos permitir uma taxa de disparo maior que 180";

				if (!this.shot._id && !schedule.finish_date)
					invalidDate = "Complete as configurações de disparo";

				if (!this.shot._id && (moment(schedule.finish_date).isBefore(moment()) || moment(schedule.begin_date).isBefore(moment())))
					invalidDate = `O período definido para ${formatPhone(schedule.phone)} é inválido`;

				if (invalidDate)
					break;
			}

			return invalidDate;
		}
	},
	methods: {
		resetShot() {
			setTimeout(() => {
				this.shot = {
					_id: null,
					schedules: [],
					pattern_id: null,
					campaign_id: this.$props.campaignId
				};
				this.phones = [];
				this.loading = true;
				this.getPhones().finally(() => (this.loading = false));
				this.$forceUpdate();
			}, 300);
		},
		toast(message, type = "danger") {
			this.$bvToast.toast(message, {
				title: "Disparos",
				autoHideDelay: 2000,
				variant: type
			});
		},
		testShot() {
			this.$refs.testShotValidationForm.validate().then((success) => {
				if (!success) return;

				const params = {
					phone: this.testPhone,
					campaignId: this.campaignId
				};

				if (this.campaignId) {
					this.loading = true;
					api.post(`/shots/test`, params)
						.then((response) => {
							const { type, message } = response.data;

							this.$swal(type === "success" ? "Sucesso" : "Erro", message, type);
						})
						.catch((err) => {
							let message =
								"Houve um erro ao tentar realizar solicitação, tente novamente.";

							if (err.response) {
								message = err.response.data.message;
							}
							this.$swal("Erro", message, "error");
						})
						.finally(() => (this.loading = false));
				}
			});
		},
		async getShot() {
			const id = this.shot._id;
			if (id == null) return;

			return api.get(`/shots/${id}`).then((res) => {
				const { body, type } = res.data;

				if (type === "success") {
					return Object.assign(this.shot, body);
				}
			});
		},
		async getShotsPatterns() {
			return api
				.get("/shots/patterns", { params: { all: 1 } })
				.then((res) => {
					const { body, type } = res.data;

					if (type === "success") {
						return (this.shotsPatterns = body);
					}
					return [];
				})
				.catch(() => {
					this.shotsPatterns = [];
				});
		},
		patternCreated(pattern) {
			this.creating = false;
			this.loading = true;
			this.getShotsPatterns().finally(() => {
				this.shot.pattern_id = pattern._id;
				this.loading = false;
			});
		},
		cancel() {
			this.$swal({
				title: "Deseja voltar para a lista de campanhas?",
				showCancelButton: true,
				cancelButtonText: "Não",
				confirmButtonText: "Sim",
				confirmButtonColor: "#317f98"
			}).then((result) => {
				if (result.isConfirmed) {
					this.$router.replace({ name: "campaigns/contents" });
				}
			});
		},
		async getPhones() {
			if (!this.segmentationId || this.shot.schedules.length > 0) return;

			return api
				.get(`/segmentation/${this.segmentationId}/phones`)
				.then((res) => {
					const { body, type } = res.data;

					if (type === "success") {
						return (this.shot.schedules = body.map((phone) => {
							return {
								phone_id: phone._id,
								phone: phone.phone,
								calendar: null,
								begin_date: null,
								finish_date: null,
								is_custom: false,
								tax: 0
							};
						}));
					}
					return [];
				})
				.catch(() => {
					this.phones = [];
				});
		},
		useShotPattern(id) {
			if (id == null) {
				const applied = this.shot.schedules.map((schedule) => {
					return Object.assign(schedule, {
						begin_date: null,
						finish_date: null,
						is_custom: false,
						calendar: null
					});
				});

				return (this.shot.schedules = Array.from(applied));
			}

			this.loading = true;
			api.get(`/shots/patterns/${id}`)
				.then((res) => {
					const { body, type } = res.data;

					if (type !== "success") throw res;

					const only = ["begin_date", "finish_date", "is_custom", "calendar"];
					const entries = Object.entries(body).filter((entry) => only.includes(entry[0]));
					const filtered = Object.fromEntries(entries);

					const applied = this.shot.schedules.map((schedule) => {
						return { ...schedule, ...filtered };
					});
					this.shot.schedules = Array.from(applied);
				})
				.catch((err) => {
					console.log(err);
					let message = "Não foi possível carregar modelo de disparo.";

					if (err.response) message = err.response.data.message;

					this.toast(message);
					this.shot.pattern_id = null;
				})
				.finally(() => (this.loading = false));
		},
		submit() {
			this.$refs.shotValidationForm.validate().then(async (success) => {
				if (!success)
					return this.toast("Alguns campos não foram preenchidos corretamente.");

				const id = this.shot._id;

				if (id) {
					const result = await this.$swal({
						title: "Tem certeza?",
						text: "Ao reagendar, os disparos em andamento serão reorganizados, e qualquer falha anterior será reintegrada à fila. Somente será reagendado caso houver alterações no período de algum número de disparo.",
						type: "info",
						showCancelButton: true,
						reverseButtons: true,
						confirmButtonText: "Reagendar",
						cancelButtonText: "Cancelar"
					});

					if (!result.isConfirmed) return;
				}

				this.loading = true;
				api({
					method: id ? "PUT" : "POST",
					url: id ? `/shots/${id}` : `/shots`,
					data: this.shot
				})
					.then((res) => {
						const { body, message, type } = res.data;

						if (type === "success") {
							Object.assign(this.shot, body);
							return this.$swal({
								title: "Campanha",
								text: "Campanha agendada com sucesso",
								icon: "success"
							}).then(() => {
								this.$router.replace({ name: "campaigns" });
							});
						}

						this.toast(message);
					})
					.catch((err) => {
						let message = "Não foi possível agendar disparo.";

						if (err.response) message = err.response.data.message;

						this.toast(message);
					})
					.finally(() => (this.loading = false));
			});
		}
	}
};
</script>
