<template>
    <tree-branch>
        <template v-slot:control-addon>
            <div class="mx-2 d-flex align-items-center flex-wrap" id="answer-conditions">
                <h4
                    v-for="(neddle, i) in $props.data.neddles"
                    :key="`campaign-answer-${Date.now()}-${i}`"
                    class="m-0 d-flex align-items-center mt-2"
                >
                    <span class="badge bg-primary fs-1 mx-1 bagde-removable">
                        {{ neddle.title }}
                        
                        <font-awesome-icon
                            v-if="editable"
                            icon="fas fa-close"
                            role="button"
                            size="xs"
                            @click="() => $props.data.neddles.splice(i, 1)"
                        />
                    </span>
                </h4>
                <div class="d-flex align-items-center flex-nowrap mt-2">
                    <span v-if="editable" class="circle-icon ml-2" role="button" @click="() => addNeddleToAnswer($props.data.neddles)">
                        <font-awesome-icon icon="fas fa-plus" size="xs"/>
                    </span>
                    <font-awesome-icon
                        icon="fas fa-circle-info"
                        class="mx-2"
                        v-b-tooltip="'Algumas expressões podem ser usadas para aceitar mais variações: \\w (Qualquer texto), \\d (Qualquer número) e * (Qualquer coisa)'"
                    />
                </div>
            </div>
        </template>

        <template slot="control-addon-end">
            <div class="d-flex flex-column">
                <div class="d-flex">
                    <b-form-checkbox
                        v-model="canStoreAnswer"
                        class="mr-5"
                        size="md"
                        switch
                        :disabled="!editable"
                    >
                        Guardar Resposta
                    </b-form-checkbox>

                    <span v-if="editable" class="circle-icon ml-2" role="button" @click="() => $emit('delete')" v-b-tooltip="'Remover resposta'">
                        <font-awesome-icon icon="fas fa-minus" size="xs" />
                    </span>
                </div>

                <validation-provider
                    #default="{ failed }"
                    rules="required"
                    v-if="canStoreAnswer"
                >
                    <select :disabled="!editable" class="form-control mt-4" :class="{'is-invalid': failed}" v-model="storeAnswerOption">
                        <option :value="null">Salvar como</option>
                        <option value="existing">Campo existente</option>
                        <option value="new">Criar novo campo</option>
                    </select>
                </validation-provider>
                <div class="d-flex flex-column" v-if="$props.data.answers[0] && $props.data.answers[0].form && canStoreAnswer">
                    <small class="mr-auto mt-2">Campo selecionado:</small>
                    <input
                        type="text"
                        v-model="$props.data.answers[0].form.name"
                        placeholder="Buscar Formulário"
                        disabled
                        class="form-control"
                    >
                </div>
            </div>
        </template>

        <tree-root v-if="$props.data.answers[0]">
            <tree-branch>
                <div class="d-flex flex-row py-2" v-if="$props.data.answers[0].text != null">
                    <div class="d-flex align-items-start flex-column mx-4 flex-grow-1">
                        <h5 class="text-secondary">Réplica</h5>
                        <card-text-synonym
                            :data="$props.data.answers[0]"
                            :show-title="false"
                            class="w-100"
                            allow-attachments
                            :read-only="!editable"
                        ></card-text-synonym>
                    </div>
                    <span 
                        v-if="editable" 
                        class="circle-icon ml-2" 
                        role="button" 
                        @click="() => resetAnswer($props.data.answers[0], true)"
                        v-b-tooltip="'Remover réplica'"
                    >
                        <font-awesome-icon icon="fas fa-minus" size="xs" />
                    </span>
                </div>
                <span
                    v-if="($props.data.answers[0].text == null && editable)"
                    class="d-flex align-items-center mt-3"
                    role="button"
                    @click="() => resetAnswer($props.data.answers[0])"
                >
                    <span class="circle-icon mr-2">
                        <font-awesome-icon icon="fas fa-plus" size="xs"/>
                    </span>
                    Adicionar réplica
                </span>
            </tree-branch>
            <campaign-recursive-answer
                v-for="(answer, i) in $props.data.answers[0].neddles"
                :key="`campaign-sequential-answer-${Date.now()}-${i}`"
                :data="answer"
                :isChild="true"
                @delete="() => removeSequentialAnswer($props.data.answers[0].neddles, i)"
                :editable="editable"
            />
            <tree-branch v-if="editable && $props.data.answers[0].text != null">
                <template slot="control-button">
                    <span
                        class="d-flex align-items-center"
                        role="button"
                        @click="() => addSequentialAnswer($props.data.answers[0].neddles)"
                    >
                        <span class="circle-icon mr-2">
                            <font-awesome-icon icon="fas fa-plus" size="xs"/>
                        </span>
                        Adicionar outra condição de resposta à sequência
                    </span>
                </template>
                <add-field-name-modal
                    :id="`add-field-${uuid}`"
                    @select="updateForm"
                    @ignore="storeAnswerOption = null"
                />
            </tree-branch>
        </tree-root>
    </tree-branch>
</template>

<script>
import TreeBranch from '@/views/components/TreeLayout/TreeBranch';
import TreeRoot from '@/views/components/TreeLayout/TreeRoot';
import CardTextSynonym from '@/views/components/CardTextSynonym/index.vue';
import {BFormCheckbox} from "bootstrap-vue";
import AddFieldNameModal from "./AddFieldNameModal";
import { uuid } from 'vue-uuid';
import { ValidationProvider } from 'vee-validate';
import { required } from '@/@core/utils/validations/validations';
import { acceptedFieldNameRegex } from '@/api/constants';

export default {
    name: 'campaign-recursive-answer',
    components: {
        TreeBranch,
        TreeRoot,
        CardTextSynonym,
        BFormCheckbox,
        AddFieldNameModal,
        ValidationProvider
    },
    data() {
        return {
            modalVisible: false,
            storeAnswerOption: null,
            firstLoad: false,
            canStoreAnswer: this.$props.data.answers[0] ? this.$props.data.answers[0].storeAnswer : false,
            uuid: uuid.v4(),
            required
        }
    },
    props: {
        editable: {
            type: Boolean,
            default: true
        },
        data: {
            type: Object,
            default: null
        },
        isChild: {
            type: Boolean,
            default: false
        },
        recursive: {
            type: Boolean,
            default: true
        },
        id: {
            type: String,
            default: ''
        }
    },

    mounted() {
        this.setForm();
    },

    watch: {
        storeAnswerOption(option) {
            if (this.firstLoad)
                return this.firstLoad = !this.firstLoad;

            if (option === "existing")
                return this.$bvModal.show(`add-field-${this.uuid}`);

            if (option === "new")
                return this.addNewForm();

            this.$props.data.answers[0].form = null;
        },
        canStoreAnswer(option) {
            this.$props.data.answers[0].storeAnswer = option;
        }
    },

    methods: {
        addNeddleToAnswer(neddles) {
            this.$swal({
                title: 'Adicionar resposta',
                input: 'text',
                inputAttributes: {
                    autocapitalize: 'off'
                },
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Salvar',
                confirmButtonColor: "#317f98",
                reverseButtons: true,
            }).then((result) => {
                if (!result.isConfirmed)
                    return;

                if (!(neddles.filter(e => e.title == result.value).length > 0))
                    neddles.push({title: result.value});
            })
        },

        addSequentialAnswer(array) {
            array.push({
                neddles: [],
                answers: [{
                    condition: "Resposta",
                    text: 'Adicionar réplica',
                    attachments: [],
                    synonyms: [],
                    neddles: [],
                    storeAnswer: false,
                    form: null
                }],
            });
            this.$forceUpdate()
        },

        removeSequentialAnswer(array, index) {
            array.splice(index, 1);
            this.$forceUpdate()
        },

        updateAnswerText(val) {
            var answer = this.$props.data.answers[0];
            Object.assign(val, answer);
            this.$props.data.answers = [val];
        },

        addNewForm() {
            this.$swal({
                title: 'Novo Campo',
                input: 'text',
                inputAttributes: {
                    autocapitalize: 'off'
                },
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Salvar',
                confirmButtonColor: "#317f98",
                reverseButtons: true,
            }).then((result) => {
                if (!result.isConfirmed)
                    return this.storeAnswerOption = null;
                
                if (result.value == '' || result.value == null || !acceptedFieldNameRegex.test(result.value)) {
                    this.storeAnswerOption = null;
                    return this.$root.$bvToast.toast(
                        "Nome para o campo inválido. Somente são aceitos letras, números, hífens e underlines.", 
                        { title: "Campanhas", variant: 'danger' }
                    );
                }
                this.$props.data.answers[0].form = {
                    new: true,
                    name: result.value,
                    form_id: null
                };
                this.$forceUpdate();
            }).catch(() => this.storeAnswerOption = null);
        },

        updateForm(selected) {
            const formField = {
                new: false,
                name: selected.field,
                form_id: null
            }

            this.$props.data.answers[0].form = formField;
            this.$forceUpdate();
        },

        setForm() {
            if(!this.$props.data.answers[0])
                return;

            if (!this.$props.data.answers[0].form)
                this.$props.data.answers[0].form = null;
                
            if (this.$props.data.answers[0].form !== null && "new" in this.$props.data.answers[0].form) {
                this.firstLoad = !this.firstLoad;
                this.storeAnswerOption = this.$props.data.answers[0].form.new ? "new" : "existing"
            }
        },

        resetAnswer(answer, clear = false) {
            answer.attachments = [];
            answer.synonyms = [];

            if(clear)
                answer.text = null;
            else
                answer.text = 'Adicionar réplica';
        }
    }
}
</script>

<style scoped lang="scss">
#answer-conditions {
    max-width: 800px;
}
</style>
