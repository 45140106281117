<template>
    <b-modal
        no-close-on-backdrop
        centered
        lazy
        :id="id"
        title="Ajustar campanhas"
        hide-footer
        size="xl"
        @hide="$emit('update')"
    >
        <loading v-if="loading && value.length >= 0"/>
        <div v-if="loading && value.length == 0" class="d-flex align-items-center justify-content-center">
            <span class="spinner-border spinner-border-sm mx-2" role="status"></span>
        </div>
        <div v-else class="d-flex flex-column">
            <p class="text-center" v-if="value.length == 0">Nenhuma campanha encontrada</p>
            <div
                class="d-flex align-items-center justify-content-between my-2"
                v-for="(campaign, i) in value"
                :key="`campaign-shot-${i}`"
            >
                <b>{{ campaign.name }}</b>
                <validation-observer :ref="`${campaign._id}Validation`" tag="div" class="d-flex">
                    <validation-provider
                        #default="{ failed }"
                        rules="required"
                    >
                        <date-picker
                            placeholder="Selecione uma data"
                            prefix-class="xmx"
                            type="datetime"
                            format="DD/MM/YYYY HH:mm"
                            value-type="YYYY-MM-DD HH:mm"
                            :lang="datePickerPt"
                            v-model="getSchedule(campaign).begin_date"
                            :input-class="{ 'mx-input': true, 'border-danger': failed }"
                            :disabled-date="(date) => disableDateTime(date, getSchedule(campaign).finish_date, 'max')"
                            :disabled-time="(date) => disableDateTime(date, getSchedule(campaign).finish_date, 'max', true)"
                        ></date-picker>
                    </validation-provider>
                    <validation-provider
                        #default="{ failed }"
                        rules="required"
                    >
                        <date-picker
                            placeholder="Selecione uma data"
                            prefix-class="xmx"
                            type="datetime"
                            format="DD/MM/YYYY HH:mm"
                            value-type="YYYY-MM-DD HH:mm"
                            :lang="datePickerPt"
                            v-model="getSchedule(campaign).finish_date"
                            :input-class="{ 'mx-input': true, 'border-danger': failed }"
                            :disabled-date="(date) => disableDateTime(date, getSchedule(campaign).begin_date, 'min')"
                            :disabled-time="(date) => disableDateTime(date, getSchedule(campaign).begin_date, 'min', true)"
                        ></date-picker>
                    </validation-provider>
                    <button
                        type="button"
                        @click.prevent="saveSchedule(campaign)"
                        class="btn btn-outline-primary"
                    >
                        Ajustar
                    </button>
                </validation-observer>
            </div>
        </div>
    </b-modal>
</template>

<script>
import { BModal } from "bootstrap-vue";
import api from "@/api";
import { disableDateTime } from '@/api/functions';
import { datePickerPt } from "../constants";
import DatePicker from 'vue2-datepicker';
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { required } from "@/@core/utils/validations/validations";
import Loading from "@/views/components/Loading.vue";

export default {
    components: {
    BModal,
    DatePicker,
    ValidationObserver,
    ValidationProvider,
    Loading
},
    data() {
        return {
            loading: false,
            datePickerPt,
            required
        };
    },
    props: {
        value: {
            type: Array,
            required: false,
            default: () => []
        },
        phoneId: {
            type: String,
            required: true
        },
        id: {
            type: String,
            default: 'adjustShotPhoneModal'
        }
    },
    emits: ['update'],
    methods: {
        disableDateTime,
        toast(message, type = "danger") {
            this.$bvToast.toast(message, {
                title: 'Ajustar campanhas',
                autoHideDelay: 2000,
                variant: type
            })
        },
        getSchedule(campaign) {
            const phoneId = this.phoneId;
            const index = campaign.shot.schedules.findIndex(e => e.phone_id == phoneId);
            return campaign.shot.schedules[index];
        },
        saveSchedule(campaign) {
            const ref = `${campaign._id}Validation`;
            this.$refs[ref][0].validate().then(success => {
                if(!success) return;

                this.loading = true;
                api.put(`/shots/${campaign.shot_id}`, campaign.shot)
                .then(res => {
                    const { type, message } = res.data;

                    if(type == 'success') {
                        return this.toast(message, 'success');
                    }
                    throw res;
                })
                .catch((err) => {
                    console.log(err)
                    let message = "Não foi possível ajustar o período dessa campanha.";

                    if(err.response) {
                        message = err.response.data.message;
                    }

                    console.log('errir')

                    this.toast(message);
                })
                .finally(() => this.loading = false);
            })
        }
    },
}
</script>
